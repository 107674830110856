import React, {Suspense, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Language from "./language";

import * as FaIcons from "react-icons/fa";
import BASIC_INFORMATION from '../service/information/basic';
import Section from "./section";
import {Link} from "react-router-dom";
const SectionSupport = React.lazy(() => import('../components/section/support_section'));

function Footer() {
    const { t, i18n } = useTranslation();

    // const [service, setService] = useState([]);
    //
    // useEffect(() => {
    //     const data = localStorage.getItem('SERVICES');
    //     if ( data !== null ) setService(JSON.parse(data));
    // }, []);

    return (
        <div>
            {/*<div className="bg-white pt-5">*/}
            {/*    <div className={'container'}>*/}

            {/*        <div className="d-flex flex-wrap">*/}

            {/*            <div className="col-md-6 d-flex flex-wrap align-items-center p-3 pt-0 pb-mb-0 pb-5">*/}

            {/*                <div className="col-md-12 text-start">*/}
            {/*                    <h2 className={'display-4'}>*/}
            {/*                        <b>Track progress your project</b>*/}
            {/*                    </h2>*/}
            {/*                    <br/>*/}
            {/*                    <span className={'lead text-secondary m-0'}>{t('nav.app.download')}</span>*/}
            {/*                    <div className="d-flex text-start mt-3">*/}
            {/*                        <img src="/assets/app/android.svg" alt="android" className={'img-fluid col-md-4 p-2'}/>*/}
            {/*                        <img src="/assets/app/ios.svg" alt="ios" className={'img-fluid col-md-4 p-2'}/>*/}
            {/*                        <div className="col-md-4"></div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div className="col-md-6 text-end">*/}
            {/*                <img src="/assets/app/app.png" alt="ios-android-app" className={'img-fluid'}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}
            {/*<Suspense fallback={<div>Loading...</div>}><SectionSupport /></Suspense>*/}
            {/*<Section title={''} name={''} child={<div className={'navbar'}>*/}
            {/*    <div className="d-flex align-items-center m-auto">*/}
            {/*        <a href="/" className={'text-decoration-none'}>*/}
            {/*            <h2 className={'color-main display-5'}>*/}
            {/*                Growth  <img src="https://static.vecteezy.com/system/resources/previews/005/835/570/non_2x/ukraine-flag-national-europe-emblem-map-icon-illustration-abstract-design-element-free-vector.jpg" alt="Ukraine" width="70"/> Ukraine*/}
            {/*            </h2>*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*</div>} link={'/'} linkName={''}/>*/}
            <footer>
                <div className={'footer-links'}>

                    {/*<div className="col-md-10 m-auto p-md-0 p-3">*/}
                    {/*    <div className="pb-5 d-flex justify-content-between align-items-center pt-5">*/}
                    {/*        <div className="col-md-6 text-start">*/}
                    {/*            <h3 className={'text-secondary p-'}>{t('nav.online')}</h3>*/}
                    {/*            <div className="row">*/}
                    {/*                <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary col-md-4'}> <FaIcons.FaTelegram /> <span>Telegram</span></a><br/>*/}
                    {/*                <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary col-md-4'}> <FaIcons.FaWhatsapp /> <span>WhatsApp</span></a><br/>*/}
                    {/*                <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary col-md-4'}> <FaIcons.FaViber /> <span>Viber</span></a><br/>*/}
                    {/*            </div>*/}

                    {/*            <div className="text-start">*/}

                    {/*                /!**/}
                    {/*                <a id={'footer-phone'} href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'color-main'}>*/}

                    {/*                    {BASIC_INFORMATION.phoneKyivstar}*/}

                    {/*                </a>*/}
                    {/*                <br/>*/}
                    {/*                *!/*/}
                    {/*                <a id={'footer-email'}  href={*/}
                    {/*                    'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Footer'*/}
                    {/*                } className={'color-main'}>*/}

                    {/*                    <h2 className="display-5">*/}
                    {/*                        {BASIC_INFORMATION.email}*/}
                    {/*                    </h2>*/}

                    {/*                </a>*/}

                    {/*            </div>*/}
                    {/*            <div className="mt-5">*/}
                    {/*                <div className="row col-md-6">*/}
                    {/*                    <a href="/information#privacy-policy" className={'text-secondary col-md-4 col-4'}><span className={'p-0 m-0'}>{t('nav.privacy-policy')}</span></a> <br/>*/}
                    {/*                    <a href="/information#term-of-use" className={'text-secondary col-md-4 col-4'}><span className={'p-0 m-0'}>{t('nav.term-of-use')}</span></a> <br/>*/}
                    {/*                    <a href="/site-map" className={'text-secondary col-md-4 col-4'}><span className={'p-0 m-0'}>{t('nav.site-map')}</span></a>*/}
                    {/*                    <div className="col-6 m-auto d-block d-sm-none pt-5 pb-2">*/}
                    {/*                        <Language />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                /!*<p id={'copyright'} className={'pt-2 text-secondary'}>{t('copyright')} © {(new Date().getFullYear())}.  {t('title')} Group.</p>*!/*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-md-6- d-none d-sm-block text-end flex-column align-content-between">*/}
                    {/*            <h3 className={'text-secondary p- '}>{t('nav.follow-us')}</h3>*/}
                    {/*            <div className="links text-start p- pt-md-2">*/}


                    {/*            </div>*/}

                    {/*            <div className="pt-2 mt-5 text-end">*/}
                    {/*                <Language />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}




                    {/*</div>*/}

                    <div className="container-fluid bg-white pb-5 pt-5">

                        <div className="container row m-auto pb-5">
                            <div className="col-md-3 col-6 text-start">
                                <h5>
                                    {t('title.company')}
                                </h5>
                                <a href={'/contact-us'} className={'text-secondary'}><span className={'m-0'}>
                                    {t('button.contact-us')}
                                </span></a>

                            </div>
                            <div className="col-md-3 col-6 text-start">
                                <h5>{t('form.social')}</h5>
                                <a href={BASIC_INFORMATION.socialInstagram} className={'text-secondary'}> <FaIcons.FaInstagram /> <span>Instagram</span></a> <br/>
                                <a href={BASIC_INFORMATION.socialFacebook} className={'text-secondary'}> <FaIcons.FaFacebook /> <span>Facebook</span></a> <br/>
                                <a href={BASIC_INFORMATION.socialLinkedIn} className={'text-secondary'}> <FaIcons.FaLinkedinIn /> <span>LinkedinIn</span></a><br/>

                            </div>
                            <div className="col-md-6 col-12 text-start">
                                <h5>{t('nav.about-us')}</h5>
                                <p>
                                    {t('about-us.1')}
                                    {t('about-us.2')}
                                </p>
                            </div>
                        </div>

                        <div className="container d-flex justify-content-between align-items-center text-start border-top pt-5">
                           <div className="col-md-3 p-1 text-start">
                               {/*<p id={'copyright'} className={'pb-1 pt-3 text-start text-secondary'}>{t('copyright')} © {(new Date().getFullYear())}.  {t('title')} Group.</p>*/}
                               {/*<img src="/assets/logo/ai.png" alt="logo" className={'img-fluid'}/>*/}
                               <h3 className={'text-dark'}>Growth  <text className="text-warning1">Ukraine</text></h3>
                               <small className="text-secondary lead">🇺🇦️ Software Development</small>
                           </div>
                           <div className="col-md-6"></div>
                           <div className="col-md-3 text-end">
                               <p id={'copyright'} className={'pb-1 pt-3 text-secondary'}>  © {t('title')} Group {(new Date().getFullYear())} </p>
                           </div>

                       </div>
                    </div>

                </div>
            </footer>
        </div>
    );
}

export default Footer;
