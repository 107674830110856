

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import Confetti from "react-confetti";

function ThankYouPage(props) {

    const { t, i18n } = useTranslation()


    const [isSend, setIsSend] = useState(true);

    useEffect(()=>{
        setTimeout(()=>{
            setIsSend(false)
        }, 7000)
    })

    return (<div className={'vh-100'}>
        {
            isSend &&   <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                run={isSend}
                recycle={isSend}
            />
        }
        <Section title={''} name={'thank-you'}/>

        <div className="container row m-auto p-5">
            <div className="col-md-4 shadow">
                <img src="/assets/logo/growth.png" className={'img-fluid mb-3'} alt="thank-you" height={'500'}/>
                <h1>{t('page.thank-you.title')}</h1>
                <p>
                    {t('page.thank-you.subtitle')}
                </p>
            </div>
            <div className="col-md-4">
                <img src="/assets/qr-code/android_apk.png" className={'img-fluid mb-3'} alt="thank-you" height={'500'}/>
                <p>
                    Download Android Apk
                    {/*{t('page.thank-you.subtitle')}*/}
                </p>
            </div>
        </div>

        <div className="col-md-3 shadow pb-2">
            {/*https://growthukraine.com/download/android/growth_ukraine.apk*/}


        </div>
        <a href="/" id={'thank-btn-back'} className={'btn bg-dark text-white mt-5 w-25 shadow-lg'}>{t('page.thank-you.btn.back')}</a>
        <br/><br/>
        <a href="https://account.growthukraine.com/" className={'text-secondary'}>{t('page.thank-you.btn.sig-in')}</a>
        <br/><br/>
    </div>);
}

export default ThankYouPage;
