import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {BACK_END_URL} from './config';
import ProjectCard from "../../components/card/project_card";

const ProjectApi = (props) => {

    const limit = props.limit === 0 ? '' : '&limit=' + props.limit;

    const [project, setProject] = useState([]);

    useEffect(()=>{
        axios.get(BACK_END_URL + 'get-projects?sort=DESC' + limit).then(function (response){

            const getProject = response.data;
            setProject(getProject.data);
            console.log(getProject.data);
            localStorage.setItem('projects', JSON.stringify(getProject.data));

        }).catch(error => {
            console.log(error);
        })
    }, []);

    return <div>
        <div className="container d-flex flex-wrap">
            {
                project && project.map((e, index) => <ProjectCard key={index} data={e} />)
            }
        </div>
    </div>

}

export default ProjectApi