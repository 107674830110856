class BasicInformation {

    constructor() {
        this.init();
    }

    init(){

        this.email = 'hello@growthukraine.com';

        this.phoneLifecell = '+38 (093) 50 19 004';
        this.phoneKyivstar = '+38 (098) 075 13 57';

        this.socialInstagram = 'https://instagram.com/';
        this.socialFacebook = 'https://facebook.com/';
        this.socialLinkedIn = 'https://linkedin.com/';
        this.socialTelegram= 'https://t.me/YaroslavLukyanchuk';

        this.appName = 'Growth Ukraine App';
        this.iosQrCode = '/assets/qr-code/me.png';
        this.iosApp = 'https://apps.apple.com/us/app/';
        this.androidQrCode = '/assets/qr-code/me.png';
        this.androidApp = 'https://play.google.com/store/apps/details?id=';

        this.address = '';

        this.clientURL = 'https://account.growthukraine.com';
    }
}

export default new BasicInformation();