import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import FormOffer from "../components/form";
import * as FaIcons from "react-icons/fa";
import BASIC_INFORMATION from '../service/information/basic';
import './contacts.css';

function ContactsPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'pt-5'}>

        {/*TODO:*/}
        {/*map Ukraine*/}

        {/*time in Ukraine*/}

       <div className={'contacts-page container text-start mt-5 pt-5 bg-white mb-5'}>
           <div className="block d-none d-sm-block pt-5 text-center">

               <div className="map-image">
                   <div className="points mapsmall-circle1 anim animate">


                   </div>

                   <div className="points mapsmall-circle2">

                   </div>
                   <div className="points mapsmall-circle3"></div>
                   <div className="points mapsmall-circle4"></div>
                   <div className="points mapsmall-circle5"></div>
                   <div className="points mapsmall-circle6"></div>
                   <div className="points mapsmall-circle7"></div>
                   <div className="points mapsmall-circle8"></div>
                   <div className="points mapsmall-circle9"></div>
                   <div className="points mapsmall-circle10"></div>
                   <div className="points mapsmall-circle11"></div>
                   <div className="points mapsmall-circle12"></div>
                   <div className="points mapsmall-circle13"></div>

                   <div className="points mapsmall-circle14"></div>
                   <div className="points mapsmall-circle15"></div>
                   <div className="points mapsmall-circle16"></div>
                   <div className="points mapsmall-circle17"></div>


                   <svg id="my-svg">


                       <path id="p_one" d="M147 149 C147, 149  137,92 197 100  " stroke-width="1" fill="none"></path>
                       <path id="p-two" d="M147 149 C147, 149 234,110 322 164  " stroke-width="1" fill="none"></path>
                       <path id="p-three" d="M131,185 C131,185 310,36 344,83 " stroke-width="1" fill="none"></path>
                       <path id="p-four" d="M194 313 C194 313 300,50 332 119 " stroke-width="1" fill="none"></path>
                       <path id="p-five" d="M324 167 C324 167 393,122 420 184  " stroke-width="1" fill="none"></path>
                       <path id="p-six" d="M153 100 C153 100 159,47 234 72   " stroke-width="1" fill="none"></path>
                       <path id="p-seven" d="M187 122 C187 122 309,0 456 124      " stroke-width="1" fill="none"></path>
                       <path id="p-eight" d="M244 257 C244 257 310,15 590 91      " stroke-width="1" fill="none"></path>
                       <path id="p-nine" d="M242 261 C242 261 383,161 568 274  " stroke-width="1" fill="none"></path>
                       <path id="p-ten" d="M133 90 C133 90 310,15 486 150  " stroke-width="1" fill="none"></path>
                       <path id="p-eleven" d="M131 184 C147, 149 234,197 194 309  " stroke-width="1" fill="none"></path>
                       <path id="p-tewelve" d="M146 139 C147, 149 86,188 141 181 " stroke-width="1" fill="none"></path>


                       <use className="bg" href="#p_one"></use>
                       <use className="move" href="#p_one"></use>

                       <use className="bg" href="#p-two"></use>
                       <use className="move" href="#p-two"></use>

                       <use className="bg" href="#p-three"></use>
                       <use className="move" href="#p-three"></use>

                       <use className="bg" href="#p-four"></use>
                       <use className="move" href="#p-four"></use>

                       <use className="bg" href="#p-five"></use>
                       <use className="move" href="#p-five"></use>

                       <use className="bg" href="#p-six"></use>
                       <use className="move" href="#p-six"></use>
                       <use className="bg" href="#p-seven"></use>
                       <use className="move" href="#p-seven"></use>


                       <use className="bg" href="#p-eight"></use>
                       <use className="move" href="#p-eight"></use>

                       <use className="bg" href="#p-nine"></use>
                       <use className="move" href="#p-nine"></use>

                       <use className="bg" href="#p-ten"></use>
                       <use className="move" href="#p-ten"></use>
                       <use className="bg" href="#p-eleven"></use>
                       <use className="move" href="#p-eleven"></use>
                       <use className="bg" href="#p-tewelve"></use>
                       <use className="move" href="#p-tewelve"></use>

                   </svg>


               </div>

               <small>
                  <i className={'text-secondary'}> We work with world wide</i>
               </small>
           </div>

           <div className="bg-white pb-5 p-5 block border-start">
               <small className={'lead'}>
                   Let's discuss your project
               </small>
               <h1 className={'text-secondary display-1'}>
                   <b>
                       {t('nav.contacts')}
                   </b>
               </h1>
               <br/>
               <div className="contacts bg-white text-dark p-3 d-flex flex-wrap">
                   <div className={'col-md-6'}>
                       <small className="text-secondary">
                           Say HI
                       </small> <br/>
                       <a href={
                           'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Contacts'
                       } className={'text-secondary'}> <FaIcons.FaEnvelope /> {BASIC_INFORMATION.email}</a> <br/>
                       <br/>
                   </div>
                   <div className={'col-md-6'}>
                       <small className="text-secondary">
                           Meet us
                       </small> <br/>
                       <a className={'text-secondary'}>  <FaIcons.FaMapMarker /> {t('contacts.address.city')}, 10001 </a> <br/>
                   </div>
                   <div className={'col-md-6'}>
                       <small className="text-secondary">
                           Call
                       </small> <br/>
                       <a href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'text-secondary'}> <FaIcons.FaMobileAlt /> {BASIC_INFORMATION.phoneKyivstar}</a> <br/>
                       <br/>
                       <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary'}> <FaIcons.FaTelegram /> <span>Telegram</span></a><br/>
                       <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary'}> <FaIcons.FaWhatsapp /> <span>WhatsApp</span></a><br/>
                       <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary'}> <FaIcons.FaViber /> <span>Viber</span></a><br/>


                   </div>
                   {/*<a href={'tel.:' + BASIC_INFORMATION.phoneLifecell} className={'text-secondary'}> <FaIcons.FaMobileAlt /> {BASIC_INFORMATION.phoneLifecell}</a> <br/>*/}
                   <br/>

                   <div className={'col-md-6'}>
                       <small className="text-secondary">
                           Follow Us
                       </small> <br/>
                       <a href={BASIC_INFORMATION.socialInstagram} className={'text-secondary'}> <FaIcons.FaInstagram /> <span>Instagram</span></a> <br/>
                       <a href={BASIC_INFORMATION.socialFacebook} className={'text-secondary'}> <FaIcons.FaFacebook /> <span>Facebook</span></a> <br/>
                       <a href={BASIC_INFORMATION.socialLinkedIn} className={'text-secondary'}> <FaIcons.FaLinkedinIn /> <span>LinkedinIn</span></a><br/>

                   </div>
                   {/*<a className={'text-secondary'}>{t('contacts.address.street')}</a> <br/> <br/>*/}
               </div>

               {/*<br/>*/}
               {/*<h3 className={'text-secondary p-3'}>*/}
               {/*    {t('nav.info')}*/}
               {/*</h3>*/}

               {/*<div className="contacts bg-white links text-dark">*/}
               {/*    <a href="/assets/files/growthukraine_presentation.pdf" target={'_blank'} className={'text-dark'}>*/}
               {/*        <span>{t('nav.presentation')}</span>*/}
               {/*    </a>*/}
               {/*    <a href="/team" target={'_blank'} className={'text-dark'}><span>{t('nav.team')}</span></a>*/}
               {/*    <a href="/career" target={'_blank'} className={'text-dark'}><span>{t('nav.career')}</span></a>*/}
               {/*</div>*/}

           </div>

       </div>
    </div>);
}

export default ContactsPage;
