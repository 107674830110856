import React from "react";

function StackTechnologySection(){
    const _list = [
        'Laravel',
        'React JS',
        'Flutter',
        'Git',
        'PHP',
        'Dart',
        'HTML/CSS/JS',
        'Bootstrap'
    ];

    return <div>
        <h5>Stack technology</h5>
        <div id={'stack'} className={'bg-white1 d-flex justify-content-between align-items-center p-1'}>
            <div className={'col-md-2 text-center'}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png" alt="Laravel" className={'img-fluid p-5'}/>
                {/*<br/>*/}
                {/*Laravel*/}
            </div>

            <div className={'col-md-2 text-center'}>
                <img src="https://plugins.jetbrains.com/files/9212/376589/icon/pluginIcon.png" alt="Flutter" className={'img-fluid p-3'}/>
                {/*<br/>*/}
                {/*Flutter*/}
            </div>

            <div className={'col-md-2 text-center'}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png" alt="react.js" className={'img-fluid p-5'}/>
                {/*<br/>*/}
                {/*React.JS*/}
            </div>

            <div className={'col-md-2 text-center'}>
                <img src="https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png" alt="next.js" className={'img-fluid p-2'}/>
                {/*<br/>*/}
                {/*React.JS*/}
            </div>

            {/*<div className={'col-md-2 text-center'}>*/}
            {/*    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1200px-Bootstrap_logo.svg.png" alt="Bootstrap" className={'img-fluid p-5'}/>*/}
            {/*    /!*<br/>*!/*/}
            {/*    /!*Bootstrap*!/*/}
            {/*</div>*/}

            <div className={'col-md-2 text-center'}>
                <img src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/23-swift-512.png" alt="Bootstrap" className={'img-fluid p-5'}/>
                {/*<br/>*/}
                {/*Bootstrap*/}
            </div>

            {/*<div className={'col-md-2 text-center'}>*/}
            {/*    <img src="https://www.simplilearn.com/ice9/free_resources_article_thumb/MySQL-Logo.wine.png" alt="MySQL" className={'img-fluid p-5'}/>*/}
            {/*    /!*<br/>*!/*/}
            {/*    /!*MySQL*!/*/}
            {/*</div>*/}

            <div className={'col-md-2 text-center'}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png" alt="Git" className={'img-fluid p-5'}/>
            </div>

            {/*<div className={'col-md-2 text-center'}>*/}
            {/*    <img src="https://www.mamp.info/images/icons/mamp-pro.png" alt="Git" className={'img-fluid p-5'}/>*/}
            {/*</div>*/}

            {/*<div className={'col-md-2 text-center'}>*/}
            {/*    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/1200px-Visual_Studio_Code_1.35_icon.svg.png" alt="Git" className={'img-fluid p-5'}/>*/}
            {/*</div>*/}
        </div>

        <div className="d-flex border p-2">

            {
                _list.map((_, i) =>  <div key={i} className="item">
                    <img src="" alt=""/>
                    <small className="text-secondary">
                        <i>
                            {
                                _
                            }
                        </i>
                    </small>
                </div>)
            }


        </div>
    </div>;
}

export default StackTechnologySection;