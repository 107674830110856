import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import CooperationForm from "./cooperation_form";
import BASIC_INFORMATION from '../service/information/basic';

import Confetti from 'react-confetti'
import * as FaIcons from "react-icons/fa";

function FormOffer(props) {

    const { t, i18n } = useTranslation()

    const [services, setServices] = useState([]);


    // useEffect(() => {
    //
    //         let data = axios.get(BACK_END_URL + 'get-services').then(function (response){
    //             console.log(response.data);
    //             return response.data.data;
    //         }).catch(error => {
    //             console.log(error);
    //         })
    //         setServices(data);
    //         window.localStorage.setItem("services", JSON.stringify(data));
    //
    // }, [])

    return (<div className={'container'}>

        <div className="title-texts-contacts-cooperation">
            <p className={''}>
                <small className="text-dark">
                    <b>{t('nav.cooperation')}</b>
                </small>
            </p>
            <h5 className={'display-3 lead'}>

                {t('form.title2')}
            </h5>
            <h4 className={'lead'}>

                {t('form.subtitle')}
            </h4>
        </div>


        <div className="row mt-5 pt-5 min-vh-100">
            <div className="col-md-6 d-none d-md-block">

                <h6>
                    <b>{t('nav.contacts')}</b>
                </h6>

                <div className="d-flex flex-wrap pt-4 mb-5">
                    <div className="col-md-6">
                        <h6>
                            {t('title.contact.email')}
                        </h6>
                        <a href={
                            'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Form'  } className={'text-secondary text-decoration-none'}> {BASIC_INFORMATION.email}</a>
                    </div>
                    <div className="col-md-6">
                        <h6>
                            {t('title.careers')}
                        </h6>
                        <a href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'color-main1 text-secondary text-decoration-none'}> {BASIC_INFORMATION.phoneKyivstar}</a>
                    </div>

                    <hr width={'45px'} style={{color: "grey", marginTop: '50px'}} />

                    <div className="col-md-12 pt-3 mt-2">

                        <h6>
                            <b>{t('form.social')}</b>
                        </h6>
                        <div className=" mt-3 text-start">
                            <ul className={'list-inline'}>
                                <li className="list-inline-item"><a href={BASIC_INFORMATION.socialInstagram} className={'text-secondary'}> <FaIcons.FaInstagram size={25} /> </a></li>
                                <li className="list-inline-item"><a href={BASIC_INFORMATION.socialFacebook} className={'text-secondary'}> <FaIcons.FaFacebook size={25}/> </a></li>
                                <li className="list-inline-item"><a href={BASIC_INFORMATION.socialLinkedIn} className={'text-secondary'}> <FaIcons.FaLinkedinIn size={25} /> </a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-md-6">
                <h6>
                    <b>{t('form.title.from')}</b>
                </h6>
                <CooperationForm lang={i18n.language} services={services} />
            </div>
        </div>
    </div>);
}

export default FormOffer;
