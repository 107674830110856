import React from "react";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

export const SidebarData = [
    // {
    //     title: "SUPPORT",
    //     path: "/support",
    //     icon: <FaIcons.FaHeart />,
    //     cName: "nav-text"
    // },
    {
        title: "Home",
        path: "/",
        icon: <AiIcons.AiFillHome />,
        cName: "nav-text"
    },
    // {
    //     title: "Cooperation",
    //     path: "/cooperation",
    //     icon: <FaIcons.FaBriefcase />,
    //     cName: "nav-text"
    // },
    {
        title: "Projects",
        path: "/projects",
        icon: <FaIcons.FaBookmark />,
        cName: "nav-text"
    },
    {
        title: "Services",
        path: "/services",
        icon: <FaIcons.FaFileCode />,
        cName: "nav-text"
    },
    {
        title: "Products",
        path: "/products",
        icon: <FaIcons.FaLaptop />,
        cName: "nav-text"
    },
    {
        title: "Contacts",
        path: "/contacts",
        icon: <FaIcons.FaEnvelope />,
        cName: "nav-text"
    },
    // {
    //     title: "Enter",
    //     path: "auth",
    //     icon: <FaIcons.FaUser />,
    //     cName: "nav-text"
    // }
];
