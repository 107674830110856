

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";

function PageNotFound(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'container bg-white p-md-5 p-5 col-md-6 col-12 m-auto vh-100 d-flex align-items-center '}>

        <div className={'lead text-secondary text-start'}>
            <h1>
                {t('404.slogan')}
            </h1>
            <br/>
            <h3>
                <b>
                    {t('404.text.1')}
                </b>
            </h3>
            <p className={'lead'}>
                {t('404.text.2')}
                <br/>
                {t('404.text.3')}
            </p>
            <br/>
            <a href="/" id={'btn-go-home'} className={'btn1 bg-dark rounded-5 p-3 align-items-center text-white text-decoration-none p-2'}>{t('nav.home')}</a>
        </div>

    </div>);
}

export default PageNotFound;
