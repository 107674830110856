

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import {Route, Routes} from "react-router-dom";
import OfferPage from "./offer";
import ServicesPage from "./services";
import ProjectsPage from "./projects";
import PageSingle from "./single";
import ContactsPage from "./contacts";
import ThankYouPage from "./thank-you";
import PageInformation from "./information";

function PageMapSite(props) {

    const { t, i18n } = useTranslation()

    const data = JSON.parse(localStorage.getItem("projects"));

    const links = [
        {'': ''},
    ];

    return (<div>
        <Section title={t('nav.links')} name={'site-map'} child={<div className={'d-flex flex-wrap'}>

            <a href="/support" className={'col-md-6 text-decoration-none'}>

                <div className="bg-main rounded p-2 text-center">
                    <span className={'text-white'}><b>{t('ua.support')}</b></span>
                </div>
            </a>


            <a href="/cooperation" className={'col-md-3 text-decoration-none'}>

                    <div className="bg-main-outline rounded p-2 text-center">
                        {t('nav.cooperation')}
                    </div>
            </a>

            <a href="/services" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.services')}
                </div>
            </a>

            <a href="/https://account.growth-ukraine.com" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.enter')}
                </div>
            </a>

            <a href="/contacts" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.contacts')}
                </div>
            </a>

            <a href="/career" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.vacancy')}
                </div>
            </a>

            <a href="/team" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.team')}
                </div>
            </a>

            <a href="/information#privacy-policy" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.privacy-policy')}
                </div>
            </a>

            <a href="/information#term-of-use" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.term-of-use')}
                </div>
            </a>

            <a href="/site-map" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.site-map')}
                </div>
            </a>

            <a href="/projects" className={'col-md-3 text-decoration-none'}>

                <div className="bg-main-outline rounded p-2 text-center">
                    {t('nav.projects')}
                </div>

                <div className="col-md-12 d-flex flex-column list-projects">
                    {
                        data.map((e, i)=> <div className={'col-md-12'}>
                            <a href={'project/' + e.slug} className={'text-decoration-none'}>

                                <div className="bg-main-outline rounded p-2 text-center d-flex justify-content-around align-items-center">
                                    <span className="lead">
                                        #{
                                        i+1
                                    }
                                    </span>
                                    <span className={'lead'}>
                                        {
                                            e.name[i18n.language]
                                        }
                                    </span>
                                </div>
                            </a>
                        </div>)
                    }
                </div>
            </a>



        </div>}/>
    </div>);
}

export default PageMapSite;
