import React, {Suspense} from 'react';
import Section from "../components/section";
import StackTechnologySection from "../components/section/technoloy";
import {MotionAnimate} from "react-motion-animate";
import {useTranslation} from "react-i18next";

const ProjectsSection = React.lazy(() => import('../components/section/projects_section'));
const SectionSupport = React.lazy(() => import('../components/section/support_section'));
const SectionAboutUs = React.lazy(() => import('../components/section/about_section'));
const SectionPopularServices = React.lazy(() => import('../components/section/popular_services_section'));
const SectionApp = React.lazy(() => import('../components/section/app_section'));
const SectionContact = React.lazy(() => import('../components/section/contact_section'));
const SectionSocial = React.lazy(() => import('../components/section/social_section'));
const SectionPartners = React.lazy(() => import('../components/section/partners_section'));

function HomePage(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        <MotionAnimate reset={true}>

        <div className="container text-center p-5">
            <h2 className={'display-1'}>
                {t('text.into.title')}
            </h2>
            <p>
                {t('text.into.block1')}
            </p>
            <p>
                {t('text.into.block2')}
            </p>
        </div>
        </MotionAnimate>

        <Suspense fallback={<div>Loading...</div>}><SectionPartners /></Suspense>

        {/*<Section title={''} name={'projects'} child={<Suspense fallback={<div>Loading...</div>}><ProjectsSection limit={6} /></Suspense>} link={'/projects'} linkName={''}/>*/}
        {/*

         <Suspense fallback={<div>Loading...</div>}><SectionSupport /></Suspense>

        <StackTechnologySection />





        */}

        <Suspense fallback={<div>Loading...</div>}>
            <SectionPopularServices />
        </Suspense>

        <MotionAnimate reset={true}>
            <div className="mt-5 mb-5 text-start container">
                <h2 className={'display-1'}>
                    {t('nav.products')}
                </h2>

                <div className="d-flex flex-wrap">
                    <div className="col-md-6 p-1">
                        <div className={'rounded-5 shadow1 border bg-gradient-app pt-5 p-2 pb-0 hover-div'} style={{height: 'auto'}}>

                        <div className="d-flex flex-wrap">

                            <div className="col-md-8 d-flex flex-wrap align-items-center p-3 pt-0 pb-mb-0 pb-2">

                                <div className="col-md-12 text-start">
                                    <h2 className={'display-5 lead'}>
                                        <b>Random App</b>
                                    </h2>

                                    <p className={'p-1 text-white'}>
                                        Simple app for creators
                                    </p>
                                    <div className="d-flex text-start mt-3 align-items-center">

                                        <a href="https://play.google.com/store/apps/details?id=com.lukyanchuk.random_app" target={'_blank'} className={'m-0'}>
                                            <img src="/assets/app/android.svg" alt="android" className={'img-fluid'}/>
                                        </a>
                                        <a href="https://apps.lukyanchuk.com/app/random-app" className={'text-decoration-none text-white p-2'} target={'_blank'}>
                                            Know more
                                        </a>
                                        {/*<img src="/assets/app/ios.svg" alt="ios" className={'img-fluid col-md-4 p-2'}/>*/}
                                        <div className="col-md-4"></div>

                                        {/**/}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 text-end pb-2">

                                <div className="d-flex align-items-center justify-content-center p-5 d-none d-md-block"
                                     style={{
                                         backgroundImage: "url(https://apps.lukyanchuk.com/image/iphoneNEW.png)",
                                         backgroundPosition: 'center',
                                         backgroundSize: 'cover',
                                         backgroundRepeat: 'no-repeat',
                                         height: 300
                                     }}
                                >
                                    <img src={'https://office.lukyanchuk.com/storage/files/portfolio/8ihYr6s9pJUSb7B3BaAFnYzVLskerXkASPJti8aX.png'} alt="logo_app" className={'img-fluid mt-5'}/>
                                </div>

                                {/*<img src="/assets/app/app3.png" alt="ios-android-app" className={'img-fluid'}/>*/}
                                {/*<img src="https://office.lukyanchuk.com/storage/files/portfolio/8ihYr6s9pJUSb7B3BaAFnYzVLskerXkASPJti8aX.png" alt="ios-android-app" className={'img-fluid'}/>*/}
                            </div>
                        </div>
                    </div>
                    </div>
                    {/*Coming soon*/}
                    <div className="col-md-6 col-12 p-1">
                        <div className={'rounded-5 shadow1 border hover-div pt-5 p-2 pb-0'} style={{height: 'auto'}}>

                            <div className="d-flex flex-wrap">

                                <div className="col-md-8 col-12 d-flex flex-wrap align-items-center p-3 pt-0 pb-mb-0 pb-5">

                                    <div className="col-md-12 text-start">
                                        <small className="lead text-dark">
                                            {t('title.something-new')}
                                        </small>
                                        <h2 className={'display-5 lead'}>
                                            <b>{t('title.coming-soon')}</b>
                                        </h2>
                                    </div>
                                </div>

                                <div className="col-md-4 text-end d-none d-md-block">
                                    <img src="/assets/app/app3.png" alt="ios-android-app" className={'img-fluid'} style={{
                                        height: 308
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/*<div className={'mt-5 container border rounded-5 shadow bg-gradient pt-5 p-2 pb-0 d-flex align-items-center'} style={{height: '650px'}}>*/}

                {/*    <div className="d-flex align-items-center">*/}

                {/*        <div className="col-md-6 d-flex flex-wrap p-3 pt-0 pb-mb-0 pb-5">*/}

                {/*            <div className="col-md-12 text-start">*/}
                {/*                <h2 className={'display-3 lead'}>*/}
                {/*                    <b>Coffee CRM</b>*/}
                {/*                </h2>*/}
                {/*                <br/>*/}
                {/*                <span className={'lead text-white m-0'}>*/}
                {/*                    Management your coffee shop*/}
                {/*                </span>*/}
                {/*                <br/>*/}
                {/*                <div className="d-flex text-start mt-3">*/}
                {/*                    <a href="/download/growth_ukrinae.apk" className={'col-md-4 m-auto btn bg-dark text-white p-3'}>Register</a>*/}
                {/*                    <a href="#-/mobile-app" className={'col-md-4 m-auto btn bg-mai1n text-main p-3'}>Learn more</a>*/}
                {/*                    <div className="col-md-4"></div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className="col-md-6 text-end">*/}
                {/*            <img src="https://i.pinimg.com/originals/08/20/65/082065f5d7b560b8f9c4c66c94b1429e.png" alt="ios-android-app" className={'img-fluid'}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </MotionAnimate>

        <Suspense fallback={<div>Loading...</div>}><SectionApp /></Suspense>
        {/*<Suspense fallback={<div>Loading...</div>}><SectionSocial /></Suspense>*/}

        <Suspense fallback={<div>Loading...</div>}><SectionContact /></Suspense>

        {/*<Section title={t('nav.cooperation')} name={'cooperation'} child={<FormOffer />} link={'/#cooperation'} linkName={''}/>*/}
    </div>);
}

export default HomePage;
