import React from 'react';
import {useTranslation} from "react-i18next";
import './page.css';

function PageTemplate(props) {

    const { t, i18n } = useTranslation()

    /*
    * 1529333034259-bc9d027df718
    * 1492999104346-cabaa757be8f
    *
    * 1527443224154-c4a3942d3acf - OK
    * */
    //https://www.apple.com/v/iphone-14-pro/c/images/overview/dynamic-island/dynamic_hw__wx47n1mguoi6_large.png
    //https://images.unsplash.com/photo-1604948501466-4e9c339b9c24?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
    // https://cdn.wccftech.com/wp-content/uploads/2022/03/hh.jpg
    //https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
    // https://images.unsplash.com/photo-1695973056909-67189edc1c9ebg.jpeg
    const styleBg  = {
        backgroundImage: `url("https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/HR282_AV1_GEO_US?wid=1144&hei=1144&fmt=jpeg&qlt=90&.v=1695685413976")`,
        // backgroundImage: `url("https://images.unsplash.com/photo-1475274047050-1d0c0975c63e")`,
        // backgroundImage: `url("/assets/bg/bg.jpeg")`,
        // backgroundImage: `url("/assets/app/app3.png")`,
        // backgroundImage: `url("/assets/bg/iphone.png")`,
        backgroundSize:"contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    }

    return (<div className={'page-header pt-5_mt-5 '}>
        {
            window.location.pathname === '/' &&
            <div id={'mbg'} className={"title-company title-page-header rounded-5 bg-white pt-5 vh-100- h-auto container"}
                                                      // style={styleBg}
            >
                <h2 className="display-1">
                    {t('page.home.title')}
                    {t('ua.info')}
                    {/*Powering Innovative Mobile and Web Apps*/}
                    {/*Розробка інноваційних мобільних і веб-додатків*/}
                    {/*Розробка інноваційних програм для iOS і веб-додатків*/}
                </h2>

                <div className="content-page-header text-start- min-vh-100">
                    {/*<img src="/assets/bg/bg.jpeg" alt="image" className={'img-fluid rounded-5'} style={{height: "70vh", width: "100vw", objectFit: "cover"}}/>*/}
                    {/*<img src="https://napi.growthukraine.com/assets2/images/hero-figure.png" alt="image" className={'img-fluid rounded-5'} style={{height: "100%", width: "100vw", objectFit: "cover"}}/>*/}
                    {/*<img src="https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/HR282_AV1_GEO_US?wid=1144&hei=1144&fmt=jpeg&qlt=90&.v=1695685413976" alt="image" className={'img-fluid rounded-5'} style={{height: "100%", width: "100vw", objectFit: "cover"}}/>*/}
                    <h1 className={'color-main- text-dark animate__animated animate__slideInUp display-6'}>
                        {/*{props.slogan}*/}
                        {/*Software Development <br/> and Innovations Partner*/}
                        <b className={'text-uppercase display-md-3'}></b>
                        {/*<small className={'text-dark m-3 mt-0 mb-0'}>{t('ua.info')}</small>*/}
                    </h1>

                    <p className={'lead h6 text-secondary text-uppercase- animate__animated animate__slideInDown'}>
                        {/*<img src="https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/HR282_AV1_GEO_US?wid=1144&hei=1144&fmt=jpeg&qlt=90&.v=1695685413976"*/}
                        {/*     alt="iphone" className={'img-fluid'}/>*/}
                        {/*{props.description}*/}
                        {/*We build, grow and support high-grade solutions for businesses across the globe,<br/> backed by a proven track record of success.*/}
                    </p>
                </div>

                {/*<div className={'d-flex justify-content-between align-items-center'}>*/}
                {/*    <h1 className={'color-main animate__animated animate__slideInUp display-1 position-absolute'}>*/}
                {/*        {props.slogan}*/}
                {/*        <b className={'text-uppercase display-md-3'}></b>*/}
                {/*    </h1>*/}
                {/*    <img*/}
                {/*        className={'animate__animated animate__slideInUp'}*/}
                {/*        src="https://freepngimg.com/save/117088-12-iphone-free-download-png-hd/546x994"*/}
                {/*        alt="iphone"*/}
                {/*    />*/}

                {/*    <img*/}
                {/*        className={'animate__animated animate__slideInUp'}*/}
                {/*        src="https://www.apple.com/newsroom/images/product/os/ios/standard/Apple_ios14-widgets-redesigned_06222020.jpg.news_app_ed.jpg"*/}
                {/*        alt="iphone"*/}
                {/*    />*/}


                {/*</div>*/}

            </div>
        }

        {
            props.child
        }
    </div>);
}

export default PageTemplate;
