
import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";
import Section from "../section";
import './section.projects.css';

function ProjectsSection(props) {

    const { t, i18n } = useTranslation()

    return (<div>

        <div className="container m-auto">
            <h2>
                Case company
            </h2>

            <p>
                We will find the best solution for you, let's cooperate with us
            </p>
        </div>

        {/*TODO: random bg gradient*/}

        {/*For gradient BG: https://mycolor.space/gradient*/}

        <div className="d-flex flex-wrap">

            <div className="col-md-3 p-2 ">
                <div className="p-5 rounded-5 shadow bg-gradient">
                    <h3>Title project</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consequuntur cumque, doloremque facere facilis hic
                    </p>
                    <div className="text-center">
                        <img src="https://www.vyaparinfotech.com/vi-viadmin/uploads/product/cat_pd_image/ios-app-development.png" alt="image" width={'300'} className={'img-fluid'}/>
                    </div>
                </div>
            </div>
            <div className="col-md-3 p-2 ">
                <div className="p-5 rounded-5 shadow bg-gradient2">
                    <h3>Title project</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consequuntur cumque, doloremque facere facilis hic
                    </p>
                    <div className="text-center">
                        <img src="https://png.monster/wp-content/uploads/2022/09/png.monster-209-323x370.png" alt="image" width={'300'} className={'img-fluid'}/>
                    </div>
                </div>
            </div>
            <div className="col-md-3 p-2 ">
                <div className="p-5 rounded-5 shadow bg-gradient">
                    <h3>Title project</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consequuntur cumque, doloremque facere facilis hic
                    </p>
                    <div className="text-center">
                        <img src="https://i.pinimg.com/originals/08/20/65/082065f5d7b560b8f9c4c66c94b1429e.png" alt="image" width={'300'} className={'img-fluid'}/>
                    </div>
                </div>
            </div>
            <div className="col-md-3 p-2 ">
                <div className="p-5 rounded-5 shadow bg-gradient2">
                    <h3>Title project</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consequuntur cumque, doloremque facere facilis hic
                    </p>
                    <div className="text-center">
                        <img src="https://png.monster/wp-content/uploads/2022/09/png.monster-209-323x370.png" alt="image" width={'300'} className={'img-fluid'}/>
                    </div>
                </div>
            </div>

        </div>

        <ProjectApi limit={props.limit} />
    </div>);
}

export default ProjectsSection;