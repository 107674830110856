

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import BASIC_INFORMATION from "../service/information/basic";

function PageInformation(props) {

    const { t, i18n } = useTranslation()

    return (<div  className={'container'}>
        <Section title={''} name={'information'} child={
            <div>
                <div id="privacy-policy">
                    <h4>
                        {t('nav.privacy-policy')}
                    </h4>
                    <p className={'text-justify'}>
                        <div dangerouslySetInnerHTML={{__html: t('privacy-policy.01') }} />
                    </p>
                    <p className={'text-justify'}>
                        <div dangerouslySetInnerHTML={{__html: t('privacy-policy.02') }} />
                    </p>
                    <p className={'text-justify'}>
                        <div dangerouslySetInnerHTML={{__html: t('privacy-policy.03') }} />
                    </p>
                    <p className={'text-justify'}>
                        <div dangerouslySetInnerHTML={{__html: t('privacy-policy.04') }} />
                    </p>
                    <p className={'text-justify'}>
                        <div dangerouslySetInnerHTML={{__html: t('privacy-policy.05') }} />
                    </p>
                </div>

                <div id="term-of-use">
                    <h4>
                        {t('nav.term-of-use')}
                    </h4>
                    <p className={'text-justify'}>
                        <div dangerouslySetInnerHTML={{__html: t('term-of-use.01') }} />
                    </p>
                </div>
            </div>
        }/>
        <div className="container text-start pb-5">
            <small>
                <i className="text-secondary p-3 text-opacity-50">{t('update')} 01.09.2022</i>
            </small>
        </div>
    </div>);
}

export default PageInformation;
