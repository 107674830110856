import React from "react";
import {useTranslation} from "react-i18next";
import FileCard from "./file_card";

function ProjectCard(props) {
    const { t, i18n } = useTranslation();

    var sectionStyle = {
        width: "20vw",
        height: "300px",
        // backgroundImage: "url(" +  'https://images.unsplash.com/photo-1557180295-76eee20ae8aa' + ")"
    };

    return (


            <div className="card-project col-md-6 col-12 p-2 mb-3 animate__animated animate__zoomIn text-center">

                <div id="portfolioImage" className="card-project-bg shadow rounded">
                    <a href={'/project/' + props.data.slug}>
                    <img
                        src={props.data.image}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://growth-ukraine.online/assets/logo/growth.png"
                        }}
                        alt="portfolio-image"
                        className="img-fluid card-project-img"/>
                    <div className="info pt-5 rounded text-start">
                        <h2 className={'p-2'}>{props.data.name[i18n.language]}</h2>
                        <span className="text-secondary p-0 m-1">#{props.data.service.name[i18n.language]}</span>
                        <br/>
                        <p className={'p-2'}>{
                            props.data.description[i18n.language] == null ? '0' : props.data.description[i18n.language].length > 200 ?
                                props.data.description[i18n.language].substr(0, 200) + '...'
                                : props.data.description[i18n.language]
                        }</p>
                        <a href={props.data.links['repository_url'] ?? 'https://example.com' } className="text-white" target={'_blank'}>
                            <i className="bi-link-45deg m-1" role="img" aria-label="Eyeglasses"></i>
                            { props.data.links['repository_url'] ?? 'https://example.com' }
                            {/*i18n.language === 'ua' ? 'Демо' : 'Demo'*/}
                        </a>
                        {/*<a href={props.data.links['production_link']} className="text-white">*/}
                        {/*    <i className="bi-github m-1" role="img" aria-label="GitHub"></i>*/}
                        {/*    {i18n.language === 'ua' ? 'Репозиторій' : 'Repository' }*/}
                        {/*</a>*/}
                    </div>
                    </a>
                </div>

            </div>

    );

}

export default ProjectCard;