import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import FormOffer from "../components/form";
import CooperationForm from "../components/cooperation_form";

function OfferPage(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        <Section title={''} name={'offer'} child={<div>
            <FormOffer />
        </div>}/>
    </div>);
}

export default OfferPage;
